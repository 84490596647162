<template>
  <div>
    <div>
      <el-page-header @back="goBack" content="投资详情">
      </el-page-header>
    </div>

    <!--内容-->
    <div style="padding-top: 50px" v-loading="loading">
      <el-table
          :data="list"
          border
          style="width: 100%">
        <el-table-column
            fixed
            prop="user.registerMail"
            label="用户邮箱"
            align="center">
        </el-table-column>
        <el-table-column
            prop="user.realName"
            label="用户姓名"
            align="center">
        </el-table-column>

        <el-table-column
            prop="investmentNum"
            label="投资数量"
            align="center">
        </el-table-column>

        <el-table-column
            prop="createTime"
            label="投资时间"
            align="center">
        </el-table-column>

        <el-table-column
            prop="createTime"
            label="投资状态"
            align="center">
          <template slot-scope="scope">
            <el-tag
                :type="scope.row.taskStatus===1?'success':'info'"
                effect="dark">
              {{ scope.row.taskStatus === 1 ? '进行中' : '已结束' }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column
            align="center"
            fixed="right"
            label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="over(scope.row.id)">结束并获取收益</el-button>
          </template>
        </el-table-column>

      </el-table>
    </div>

    <!--分页-->
    <div style="padding-top: 50px;text-align: center">
      <el-pagination
          class="pagiantion"
          :total="total"
          @current-change="handleSelectionChange"
          :current-page="project.pageIndex"
          :page-size="project.pageSize"
          layout="total, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  name: "ProjectDetal",
  data() {
    return {
      num: 1,
      loading: false,
      total: 0,
      list: [],
      project: {
        projectId: null,
        pageIndex: 1,
        pageSize: 5
      }
    }
  },
  methods: {
    over(id) {
      this.$confirm('确定执行吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.get('/project/aheadTask?id=' + id).then(res => {
          if (res.data.code === 200) {
            this.findTouZiDetail()
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
          }
        })
      })
    },
    handleSelectionChange(currentPage) {
      this.project.pageIndex = currentPage;
      this.findTouZiDetail();
    },
    goBack() {
      this.$router.replace('/projectList')
    },
    findTouZiDetail() {
      this.loading = true
      this.axios.post('/project/findTouZiDetail', this.project).then(resp => {
        if (resp.data.code == 200) {
          this.list = resp.data.data.data;
          this.total = resp.data.data.total;
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  created() {
    this.project.projectId = this.$route.query.id
    this.findTouZiDetail()
  }
}
</script>

<style scoped>

</style>
